.portfolioContainer {
    padding-top: 1px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 40px;
}

.projectContainer {
    display: grid;
    grid-template-columns: auto auto;
}

.project-img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.projectCard {
    height: 360px;
    width: 440px;
    margin: 24px;
    position: relative;
}

.overlay {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    transition: 0.5s ease;
}

.tryLink {
    border: 4px solid #fa2759;
    color: #fa2759;
    text-decoration: none;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 20px;
}

.tryLink:hover {
    background-color: #fa2759;
    color: white;
}

.projectCard:hover .overlay {
    opacity: 1;
}

@media screen and (max-width: 775px) {
    .projectContainer {
        grid-template-columns: auto;
    }
}

@media (max-width: 1050px) {
    .projectCard {
        height: 270px;
        width: 330px;
    }
}

@media (max-width: 450px) {
    .projectCard .overlay {
        opacity: 1;
    }
}

.paragraph-projects {
    margin-right: 8px;
    margin-left: 8px;
}