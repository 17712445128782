.formFields {
    display: flex;
    flex-direction: column;
}

.formContainer {
    width: 500px;
}

.left {
    text-align: left;
}

.formContainerWrapper {
    display: flex;
    justify-content: center;
}

.padding-bottom-l {
    padding-bottom: 60px;
}

.sendBtn {
    color: #2dfcd8;
    border: 1px solid #2dfcd8;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 24px;
    padding-right: 24px;
    width: fit-content;
    align-self: flex-end;
    background: black;
}

.sendBtn:hover {
    color: black;
    background-color: #2dfcd8;
    cursor: pointer;
}

.mb-12 {
    margin-bottom: 12px;
}

.textfeld {
    height: 24px;
    background-color: #1a1a1a;
    border: 0;
    color: white;
    padding-left: 8px;
}

.textarea {
    background-color: #1a1a1a;
    border: 0;
    color: white;
    padding-left: 8px;
    height: 160px;
    resize: none;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.mb-24 {
    margin-bottom: 24px;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

@media screen and (max-width: 560px) {
    .formContainer {
        width: 90%;
    }
    .smallSize {
        font-size: 48px;
    }
}