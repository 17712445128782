.grey-background {
    background-color: #1a1a1a;
}

h2 {
    font-size: 36px;
}

.text-center {
    text-align: center;
}

.skillsContainer {
    padding-top: 1px;
    padding-bottom: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.skillCardContainer {
    display: grid;
    grid-template-columns: 180px 180px 180px;
}

.card {
    margin-left: 12px;
    margin-right: 12px;
    margin-top: 36px;
    margin-bottom: 36px;
    background-color: white;
    height: 100px;
    border-radius: 4px;
}

.skill-img {
    padding: 5px;
    height: 50px;
    width: 50px;
    position: relative;
    border-radius: 100%;
    top: -30px;
    box-shadow: 1px 2px rgba(0, 0, 0, 0.1);
    background-color: white;
}

.skill-text {
    position: relative;
    top: -20px;
}

@media screen and (max-width: 560px) {
    .skillCardContainer {
        grid-template-columns: 110px 110px 110px;
    }
    .card {
        margin-left: 6px;
        margin-right: 6px;
        height: 80px;
    }
    .skill-img {
        padding: 1px;
        top: -26px;
    }
}

.py {
    object-fit: cover;
}