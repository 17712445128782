body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
    scroll-behavior: smooth;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.padding-sites {
    padding-left: 120px;
    padding-right: 120px;
}

@media (max-width: 1500px) {
    .padding-sites {
        padding-left: 60px;
        padding-right: 60px;
    }
}

.primary {
    color: #fa2759 !important;
}

.secondary {
    color: #2dfcd8;
}

.grey {
    color: #1a1a1a;
}

.black {
    color: #000000;
}

.black-background {
    background-color: #000000;
}

.white {
    color: white !important;
}

.margin-xs {
    margin: 12px;
}

.margin-s {
    margin: 24px;
}

.margin-m {
    margin: 40px;
}

.margin-l {
    margin: 54px;
}

.margin-xl {
    margin: 60px;
}

.margin-xxl {
    margin: 105px;
}

.margin-left-s {
    margin-left: 24px;
}

.margin-right-s {
    margin-right: 24px;
}

@media screen and (max-width: 570px) {
    .padding-sites {
        padding-left: 24px;
        padding-right: 24px;
    }
}